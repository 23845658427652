import ReconnectingWebSocket from "reconnecting-websocket";
import { environment } from "../environments";
import { getNotSeenNotifications } from "../services/notifications.service";
import { getUserMessagesNotSeen } from "../services/chat.service";
import { store } from "../store/configure.store";
import NotifSlice from "../store/slices/notification.slice";
import ChatSlice from "../store/slices/chat.slice";
import ModalSlice from "../store/slices/modal.slice";

let ws = null;

const playNotificationSound = (notificationData) => {
  const me = store.getState().meStore as any;
  if (
    me.soundNotificationOptin &&
    notificationData.message.sender.id !== me.id
  ) {
    try {
      const audio = new Audio("/sound1.wav");
      audio.play();
    } catch(e) {
      console.error(e)
    }
  }
};

export const initWebsocketConnection = (token: string) => {
  if (!ws && token) {
    console.log("initWebsocketConnection ok");
    ws = new ReconnectingWebSocket(environment.socketUrl, [], {
      minReconnectionDelay: 500,
      maxReconnectionDelay: 500,
      reconnectionDelayGrowFactor: 1,
      debug: true,
    });
    ws.onopen = () => {
      console.log(
        "Socket on open",
        JSON.stringify({
          action: "auth",
          token: token,
        })
      );
      try {
        ws.send(
          JSON.stringify({
            action: "auth",
            token: token,
          })
        );
      } catch (e) {
        console.log("socket error >>", e);
      }
    };
    ws.onmessage = async (e) => {
      console.log("Socket on message", JSON.parse(e.data));
      try {
        const data = JSON.parse(e.data);

        if (data.type == "notification") {
          console.log("notifications");
          const notSeen = await getNotSeenNotifications();
          console.log("notSeen", notSeen);
          store.dispatch(
            NotifSlice.actions.setNotificationsNotSeen(Number(notSeen.count))
          );
        }

        if (data.type === "chat_message") {
          playNotificationSound(data);
          console.log("new messages not seen !!");
          const messagesNotSeen = await getUserMessagesNotSeen();
          store.dispatch(ChatSlice.actions.setMessagesNotSeen(messagesNotSeen));
          const totalMessagesNotSeen = Object.keys(messagesNotSeen).reduce(
            (acc: any, curr: any) => acc + messagesNotSeen[curr],
            0
          );
          store.dispatch(
            NotifSlice.actions.setMessagesNotSeen(totalMessagesNotSeen)
          );
        }

        if (data.type === "profile_view") {
          store.dispatch(NotifSlice.actions.addViewsNotSeen(1));
        }

        if (data.type === "video_call_request") {
          store.dispatch(
            ModalSlice.actions.setAnswerCall({
              visible: true,
              callId: data.callId,
              user: data.user,
            })
          );
        }
        /*
        if (data.type === "live_started") {
          store.dispatch(NotifSlice.actions.incrementOngoingLiveStreams({}));
        }
        if (data.type === "live_ended") {
          store.dispatch(NotifSlice.actions.decrementOngoingLiveStreams({}));
        }
        */
      } catch (error) {
        if (process.env.DEV) {
          console.error(error);
        }
      }
    };
    ws.onclose = (e) => {
      console.log("Socket on close", e);
      // ws = null;
    };
    ws.onerror = (e: any) => {
      window.location.reload();
    };
  }
};

export const closeWebsocketConnection = () => {
  try {
    ws.close();
    ws = null;
  } catch (e) {
    console.log("socket error >>", e);
  }
};

export const getWebsocketConnection = () => {
  if (!ws) return false;
  return ws;
};
